import React from 'react';
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import { useParams, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInfo } from '@fortawesome/free-solid-svg-icons'
import ImportExport from '../../components/m-energies-solutions/importExport'


class ListProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            dataUser: this.props.dataUser,
            dataTable: [
                {
                    name: "Climatiseur",
                    description: "Produit principal",
                    to: "climatiseur"
                },
                {
                    name: "Chaudière fioul et gaz",
                    description: "Produit principal",
                    to: "chaudiere-gaz-fioul"
                },
                {
                    name: "Pompe à chaleur",
                    description: "Produit principal",
                    to: "pompe-a-chaleur",
                },
                {
                    name: "Adoucisseur",
                    description: "Produit principal",
                    to: "adoucisseur"
                },
                {
                    name: "Chaudière à bois",
                    description: "Produit principal",
                    to: "chaudiere-a-bois"
                },
                {
                    name: "Poêle à pellet",
                    description: "Produit principal",
                    to: "poele-a-pellet"
                },
                {
                    name: "Thermodynamique",
                    description: "Produit principal",
                    to: "thermodynamique"
                },
                {
                    name: "Cumulus",
                    description: "Produit secondaire, accessoire",
                    to: "cumulus"
                },
                {
                    name: "Filtre anti-tartre",
                    description: "Produit secondaire, accessoire",
                    to: "antitartres"
                },
                {
                    name: "Régulateur ",
                    description: "Produit secondaire, accessoire",
                    to: "reguls"
                },
                {
                    name: "Désembouage",
                    description: "Produit secondaire, accessoire",
                    to: "desembouages"
                },
                {
                    name: "Pots de récupération",
                    description: "Produit secondaire, accessoire",
                    to: "pots"
                }
            ]
        }

    }


    render() {
        return (
            <>
                <Navbar where={"M-energies Solutions produits"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className=" bg-gray-200 h-full flex flex-col">
                        <div class="transition-colors duration-300 flex justify-between  bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Liste des produits </h3>
                            <div className='flex my-auto'>
                                <ImportExport accessToken={this.props.accessToken} dataUser={this.props.dataUser} />
                            </div>
                        </div>


                        <div className='grid grid-cols-5 gap-8 max-h-[40vw] px-10 py-[1vw]'>
                            {this.state.dataTable
                                ? this.state.dataTable.map((data) =>
                                    <div className='flex flex-col h-[8vw]'>
                                        <div className='px-6 py-3 bg-white rounded-t h-full'>
                                            <h2 className='text-[1.1vw] font-medium'> {data.name} </h2>
                                            <p>
                                                {data.description}
                                            </p>
                                        </div>
                                        <div className={'bg-gray-300 text-gray-500 rounded-b text-center py-2 grid grid-cols-2'}>
                                            <Link to={`/tableau-des-produits/${data.to}`}>
                                                <svg class="w-5 h-5 flex items-center cursor-pointer text-gray-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                                    <FontAwesomeIcon icon={faInfo} />
                                                </svg>
                                            </Link>
                                            <Link to={`/nouveau-produit/${data.to}`}>
                                                <svg class="w-5 h-5 flex items-center cursor-pointer text-gray-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                )
                                : null}
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default (props) => (
    <ListProducts
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);