import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingBar from "./loadBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faInfo } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import axios from 'axios';

class DynamicTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rounded: this.props.rounded || "rounded-lg",
            maxHeightTable: this.props.maxHeightTable || "ecran2:max-h-[39vw] max-h-[34vw]",
            maxWidthTable: this.props.maxWidthTable || "ecran2:max-w-[83vw] max-w-[79vw]",
            structureTable: this.props.structureTable,
            dataTable: this.props.dataTable,
            optionRow: this.props.optionRow || null,
            navigation: this.props.navigation || null,
            fonctionRow: this.props.fonctionRow || null
        }
        this.delete = this.delete.bind(this)
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.checkPropsDataTable(),
            30
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    checkPropsDataTable() {
        if (this.state.dataTable != this.props.dataTable) {
            this.setState({
                dataTable: this.props.dataTable
            });
        }
    }

    openPopUp(idProductToDelete) {
        document.getElementById('inputVerification').value = null;
        document.getElementById('popup').classList.remove('hidden')
        document.body.classList.add('overflow-hidden')
        document.getElementById('messageErrorInput').classList.add('hidden')
        document.getElementById('idProductToDelete').value = idProductToDelete;
    }

    closePopUp() {
        document.getElementById('popup').classList.add('hidden')
        document.body.classList.remove('overflow-hidden')
        document.getElementById('messageErrorInput').classList.add('hidden')
        document.getElementById('idProductToDelete').value = null;
        document.getElementById('inputVerification').value = null;
    }

    async scrollFunction(element) {
        if (document.getElementById("leadRow").classList.contains("hidden") &&
            Math.round(element.currentTarget.scrollTop) >= (Math.round(element.currentTarget.scrollHeight) - Math.round(element.currentTarget.clientHeight) - 1) &&
            Math.round(element.currentTarget.scrollTop) <= (Math.round(element.currentTarget.scrollHeight) - Math.round(element.currentTarget.clientHeight) + 1)
        ) {
            document.getElementById("leadRow").classList.remove("hidden")
            await this.props.scrollFunction(() => { document.getElementById("leadRow").classList.add("hidden") })
        }
    }

    async delete() {
        let valueVerification = document.getElementById('inputVerification').value;
        if (valueVerification === "SUPPRIMER") {
            var tokenJWT = this.props.accessToken

            var config = {
                method: 'delete',
                url: this.props.buttonDelete + document.getElementById('idProductToDelete').value,
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT,
                    'Content-Type': 'application/json'
                }
            };
            document.getElementById('popup').classList.add('hidden')
            document.getElementById('spinner').classList.remove('hidden')
            document.body.classList.add('overflow-hidden')

            await axios(config)
            await this.props.refreshTable()

            document.getElementById('spinner').classList.add('hidden')
            document.body.classList.remove('overflow-hidden')
        } else {
            document.getElementById('messageErrorInput').classList.remove('hidden')
        }
    }

    render() {
        function printData(row, structure) {
            try {
                if (Array.isArray(structure.concat)) {
                    var text = ""
                    structure.concat.forEach(dataName => {
                        text += eval("row." + dataName) + " "
                    })
                    return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {text} </span>
                } else {
                    if (structure.type && structure.type == "hour") {
                        return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {formatHour(eval("row." + structure.dataName))} </span>
                    } else if (structure.type && structure.type == "perio") {
                        return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {formatperio(eval("row." + structure.dataName))} </span>
                    } else if (structure.type && structure.type == "tarifs_menergies") {
                        return <span className={"px-3 py-2 flex flex-col " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {row.tarifs.map(data =>
                            <div>
                                {data.grille.nom} : {data.ht} €
                            </div>
                        )} </span>
                    }
                    else if (structure.type && structure.type == "bool") {
                        return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {eval("row." + structure.dataName) ? "oui" : "non"} </span>
                    }
                    else if (structure.type && structure.type == "float") {
                        return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {typeof row[structure.dataName] == 'string' && parseFloat(row[structure.dataName]).toFixed(2)} </span>
                    }
                    else {
                        return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {structure.max ? eval("row." + structure.dataName).substring(0, structure.max) : eval("row." + structure.dataName)} </span>
                    }
                }

            } catch (error) {
                return null
            }
        }

        function formatHour(data) {
            try {
                if (data.includes(',')) {
                    return data.replace(',', 'h')
                }
                else {
                    return "00h00"
                }
            } catch (error) {
                return "00h00"
            }
        }

        function formatperio(data) {
            try {
                switch (data) {
                    case "J":
                        return "Jour"
                    case "M":
                        return "Matin"
                    case "A":
                        return "Après-midi"
                    default:
                        return "jour"
                }
            } catch (error) {
                return "jour"
            }
        }

        var structure =
            <tr>
                {this.props.buttonDelete
                    ? <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b p-5 tracking-wider uppercase text-xs text-left border-transparent `
                    }> </th >
                    : null
                }
                {this.props.buttonEdit
                    ? <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b p-5 tracking-wider uppercase text-xs text-left border-transparent `
                    }> </th >
                    : null
                }
                {this.props.buttonDetail
                    ? <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b p-5 tracking-wider uppercase text-xs text-left border-transparent `
                    }> </th >
                    : null
                }
                {this.state.structureTable.structure.map((structure, key) =>
                    <th key={key} className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `
                    }> {structure.title}</th >
                )}
            </tr>
        var data = this.state.dataTable.map((row, key) =>
            <tr key={key}
                onClick={() => this.state.optionRow == "link" ? this.state.fonctionRow(row) : false}
                className={`hover:bg-gray-100 text-gray-500 + ${this.state.optionRow == "link" ? "cursor-pointer" : null}`}
            >
                {this.props.buttonDelete
                    ? <td>
                        <svg onClick={() => this.openPopUp(row['id'])} xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 flex items-center cursor-pointer text-gray-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                    </td>
                    : null
                }
                {this.props.buttonEdit
                    ? <td >
                        <Link to={this.props.buttonEdit + row['id']}>
                            <svg class="w-5 h-5 flex items-center cursor-pointer text-gray-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <FontAwesomeIcon icon={faEdit} />
                            </svg>
                        </Link>
                    </td>
                    : null
                }
                {this.props.buttonDetail
                    ? <td >
                        <Link to={this.props.buttonDetail + row['id']}>
                            <svg class="w-5 h-5 flex items-center cursor-pointer text-gray-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <FontAwesomeIcon icon={faInfo} />
                            </svg>
                        </Link>
                    </td>
                    : null
                }
                {this.state.structureTable.structure.map((structure, key) =>
                    <td key={key} className="border-b border-gray-100  text-left"> {printData(row, structure)} </td>
                )}
            </tr>
        )

        var leadRow =
            this.state.structureTable.structure.map((structure, key) =>
                <td key={key} className="border-b border-gray-100 px-3 py-2 "> <LoadingBar /> </td>
            )

        return (
            <div onScroll={this.props.scrollFunction ? (element) => this.scrollFunction(element) : null} className={`mx-auto my-auto not-prose relative bg-grid-gray-100 bg-gray-50 h-full overflow-auto ${this.state.maxWidthTable} ${this.state.maxHeightTable} ${this.state.rounded == "false" ? "" : this.state.rounded}`} >
                <table className="border-collapse table-auto w-full text-sm">
                    <thead className='w-full text-gray-500'>
                        {structure}
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data}
                        <tr id='leadRow' className={`hover:bg-gray-100 text-gray-500 hidden`} >
                            {leadRow}
                        </tr>
                    </tbody>
                </table>
                <div id="popup" class="min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div onClick={this.closePopUp} class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="w-full flex flex-col max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                        <div class="text-center p-5 flex-auto justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-16 h-16 flex items-center text-red-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                            <h2 class="text-xl font-bold py-4 ">Etes-vous sûrs ?</h2>
                            <p class="text-sm text-gray-500 px-8">Si vous souhaitez réellement supprimer écrivez SUPPRIMER. Ce processus est irréversible.</p>
                        </div>
                        <samp id='messageErrorInput' class='mx-auto text-red-500 hidden'>Saisie incorrect </samp>
                        <input id='inputVerification' type='text' class="border border-gray-300 p-2 my-2 rounded-md focus:outline-none focus:ring-2 ring-blue-200 mx-auto" />
                        <input id='idProductToDelete' type='hidden' />
                        <div class="p-3  mt-2 text-center space-x-4 md:block">
                            <button onClick={this.closePopUp} class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                Annuler
                            </button>
                            <button onClick={this.delete} class="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600">
                                Suprimer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default (props) => (
    <DynamicTable
        {...props}
        navigation={useNavigate()}
    />
);