import { faRupiahSign, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { Input } from "postcss";
import React, { Component } from "react";
import LinkProductAccessory from "./moduleForm/linkProductAccessory";
import InputPrix from "./moduleForm/inputPrix";
import InputSelect from "./moduleForm/inputSelect";
import InputTextarea from "./moduleForm/inputTextarea"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { faFileExport, faFileImport, faFileExcel, faXmark, } from '@fortawesome/free-solid-svg-icons'

class formAddProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: this.props.jsonForm,
      processData: this.props.processData,
      dataProduct: this.props.dataProduct,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.convertCsvToJson = this.convertCsvToJson.bind(this)
  }

  async handleSubmit(event) {
    document.getElementById("spinner").classList.remove("hidden");
    document.body.classList.add("overflow-hidden");
    event.preventDefault();
    let arrayData = {};


    const file = document.getElementById('dropzone-file').files[0]
    if (file) {
      // Créez un objet URL pour l'aperçu de l'image
      const objectURL = URL.createObjectURL(file);
      //var url = this.upload("677", file)
    }


    const data = new FormData(event.target);

    for (var pair of data.entries()) {
      arrayData[pair[0]] = pair[1];
    }

    // nouvbelle image si il y a file 
    arrayData["image"] = file ? await this.upload(this.props.dataProduct.id, file) : arrayData["image"]

    let jsonData = JSON.stringify(arrayData);
    //console.log(arrayData)
    //console.log(this.props.dataProduct.id)
    this.state.processData(jsonData);
    document.body.classList.remove("overflow-hidden");
    document.getElementById("spinner").classList.add("hidden");
  }

  /**
   * Envoie les fichiers à one drive
   * @param { String } id_product numéro de la commande
   */
  upload(id_product, file) {
    try {


      const newFileName = id_product; // Définir le nouveau nom du fichier (sans extension)

      const fileNameParts = file.name.split('.'); // Diviser le nom du fichier en parties avant et après le point
      const fileExtension = fileNameParts.pop(); // Obtenir l'extension du fichier

      const newFileNameWithExtension = `${newFileName}.${fileExtension}`; // Concaténer le nouveau nom avec l'extension

      const newFile = new File([file], newFileNameWithExtension, { type: file.type }); // Créer une nouvelle instance de File avec le nouveau nom

      const formData = new FormData();

      //[newFile].forEach((file, index) => { formData.append(`file`, file) })
      formData.append(`file`, newFile)

      formData.append('type', 'ecommerce');

      return axios.post('http://localhost:3010/api/onedrive/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
        .then((response) => {
          console.log(response.data.files[0])

          return response.data.files[0].fileDownloadURL
        })
        .catch((error) => { console.log(error) })


    } catch (error) { console.log(error) }
  }

  dropHandler(ev) {
    ev.preventDefault();
    document.getElementById('dropzone-file').files = ev.dataTransfer.files;
    this.convertCsvToJson()
  }

  convertCsvToJson() {
    const file = document.getElementById('dropzone-file').files[0]
    // Créez un objet URL pour l'aperçu de l'image
    const objectURL = URL.createObjectURL(file);

    document.getElementById('name_file').innerHTML = file.name
    document.getElementById('bloc_dropzone').classList.add('hidden')
    document.getElementById('preview_Imgage_block').classList.remove('hidden')
    // Sélectionnez l'élément d'image pour l'aperçu et définissez son attribut src sur l'URL de l'objet
    document.getElementById('preview_Imgage').src = objectURL;

  }

  cleanSelectFile() {
    document.getElementById('dropzone-file').value = ""
    document.getElementById('bloc_dropzone').classList.remove('hidden')
    document.getElementById('preview_Imgage_block').classList.add('hidden')
  }

  getValue(input) {
    if (input.nameValue && this.state.dataProduct) {
      return eval(input.nameValue);
    } else {
      if (input.value) {
        return input.value;
      } else {
        return null;
      }
    }
  }

  render() {
    let input = this.state.form.group.map((group) => (
      <div class="divide-y divide-gray-200">
        {group.title ? (
          <div class="  sm:py-5">
            <p class="mt-1 max-w-2xl text-sm text-gray-500">{group.title}</p>
          </div>
        ) : null}
        <div class="  sm:px-5 space-y-4 sm:py-5">
          {group.input.map((input) =>
            input.type == "hidden" ? (
              <input
                required={input.required ? true : false}
                defaultValue={input.value ? input.value : null}
                type={input.type}
                name={input.name}
                id={input.name}
              ></input>
            ) : (
              <div class="flex  w-full">
                <label
                  for={input.name}
                  class="block text-sm font-medium text-gray-700 sm:mt-px w-1/3"
                >
                  {input.label}
                </label>
                {input.type == "select" ? (
                  <InputSelect
                    input={input}
                    valueOfSelected={this.getValue(input)}
                  ></InputSelect>
                ) : input.type == "image" ? (
                  <>
                    <div className={(this.getValue(input) ? null : "hidden") + " py-3 bg-white rounded-xl relative w-1/5"} id='preview_Imgage_block'>
                      <div class="text-[#07074D] cursor-pointer absolute right  px-2 flex max-w-full w-full " onClick={this.cleanSelectFile}>
                        <span id='name_file' class="truncate text-base font-medium text-[#07074D] text-sm mr-3">

                        </span>
                        <FontAwesomeIcon className='min-w-3 h-6 ml-auto' icon={faXmark} />
                      </div>
                      <img class="m-auto px-2" id="preview_Imgage" src={this.getValue(input)}></img>

                    </div>

                    <label for="dropzone-file" id='bloc_dropzone' onDrop={this.dropHandler} onDragOver={(e) => e.preventDefault()} class={(this.getValue(input) ? "hidden" : null) + " cursor-pointer flex w-2/3 flex-col items-center rounded-xl border-2 border-dashed bg-gray-50 hover:border-gray-300 hover:bg-gray-100 bg-white p-6 text-center"}>
                      <div class="flex flex-col items-center justify-center ">
                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"> </path>
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span class="font-medium">Cliquer pour télécharger</span> ou glissez-déposez</p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, JPEG - Max : 4Mo</p>
                      </div>
                      <input id="dropzone-file" type="file" class="hidden" onChange={this.convertCsvToJson} accept="image/*" />
                    </label>

                    <input

                      placeholder={input.placeholder ? input.placeholder : null}
                      defaultValue={this.getValue(input) ? this.getValue(input) : ""}
                      type="text"
                      name={input.name}
                      id={input.name}
                      class="hidden max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8 px-2"
                    />


                  </>
                ) : input.type == "textarea" ? (
                  <InputTextarea
                    required={input.required ? true : false}
                    rows={input.rows}
                    placeholder={input.placeholder ? input.placeholder : null}
                    defaultValue={this.getValue(input)}
                    name={input.name}
                    id={input.name}
                    maxlength={input.maxlength || "255"}
                    class="block w-2/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md h-16 px-2"
                  ></InputTextarea>
                ) : input.type == "float" ? (
                  <input
                    required={input.required ? true : false}
                    placeholder={input.placeholder ? input.placeholder : null}
                    defaultValue={this.getValue(input)}
                    step="0.01"
                    type="number"
                    name={input.name}
                    id={input.name}
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8 px-2"
                  />
                )
                  : (
                    <input
                      required={input.required ? true : false}
                      placeholder={input.placeholder ? input.placeholder : null}
                      defaultValue={this.getValue(input)}
                      type={input.type}
                      name={input.name}
                      id={input.name}
                      class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8 px-2"
                    />
                  )}
              </div>
            )
          )}
        </div>
      </div>
    ));
    return (
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mb-12">
        <form
          class=" divide-y divide-gray-200 bg-gray-100 rounded-md p-5"
          onSubmit={this.handleSubmit}
          enctype="application/x-www-form-urlencoded"
        >
          <div class="space-y-6 sm:space-y-5 mb-8">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {this.state.form.title}
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                {this.state.form.description}
              </p>
            </div>
          </div>

          {input}

          {/* Les prime */}
          <div class="divide-y divide-gray-200">
            <div class="  sm:py-5">
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Prime
              </p>
            </div>
            <div class="  sm:px-5 space-y-4 sm:py-5">

              <div class="flex  w-full">
                <label for={"primeCEE"} class="flex text-sm font-medium text-gray-700 sm:mt-px w-1/3">
                  <span class="w-1/3"> Prime CEE </span>
                </label>

                <input step=".01" defaultValue={this.state.dataProduct && typeof this.state.dataProduct.primeCEE == 'string' && parseFloat(this.state.dataProduct.primeCEE).toFixed(2)} type="number" min="0" name="primeCEE" id={"primeCEE"} class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8 px-2" />

              </div>

              <div class="flex  w-full">
                <label for={"primeMaPrimeRenov"} class="flex text-sm font-medium text-gray-700 sm:mt-px w-1/3">
                  <span class="w-1/3"> MaPrimeRénov' </span>
                </label>

                <input step=".01" defaultValue={this.state.dataProduct && typeof this.state.dataProduct.primeMaPrimeRenov == 'string' && parseFloat(this.state.dataProduct.primeMaPrimeRenov).toFixed(2)} type="number" min="0" name="primeMaPrimeRenov" id={"primeMaPrimeRenov"} class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8 px-2" />

              </div>
            </div>
          </div>

          <InputPrix dataProductPrice={this.state.dataProduct ? this.state.dataProduct.tarifs : null}
            accessToken={this.props.accessToken}
          ></InputPrix>
          {this.state.form.group[0].input.filter((input) => { return input.name == "typeProduit"; })[0].value == 1 ? (
            <LinkProductAccessory
              listeAccesoires={this.state.dataProduct ? this.state.dataProduct.accessoires : []}
              accessToken={this.props.accessToken}
            ></LinkProductAccessory>
          ) : null}

          <div class="flex justify-end sm:border-t sm:border-gray-200 sm:py-5">
            <button type="submit"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Enregistrer
            </button>
          </div>
        </form>

        <div
          id="spinner"
          class=" min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"
        >
          <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
          <div class="flex justify-center items-center h-full">
            <img
              class="h-16 w-16"
              src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default formAddProducts;
