import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faXmark, faInfo } from '@fortawesome/free-solid-svg-icons'
import NotifBar from '../notifBar'

class CleanAdressByCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            where: this.props.where,
            dataUser: this.props.dataUser,
            messageErrorCleanAdress: null,
            importSucces: false,

        }
        this.convertCsvToJson = this.convertCsvToJson.bind(this)
        this.dropHandler = this.dropHandler.bind(this)
        this.displayMessageErrorImport = this.displayMessageErrorImport.bind(this)
        this.cutListAdresse = this.cutListAdresse.bind(this)
    }



    recupererHeaders(val, array = [], parent = null) {
        for (var j in val) {
            if (typeof val[j] == 'object') {
                if (Array.isArray(val[j])) {
                    val[j].map((vals, key) => {
                        this.recupererHeaders(vals, array, (parent ? parent + "." : "") + j + "[" + key + "]")
                    })
                } else {
                    this.recupererHeaders(val[j], array, (parent ? parent + "." : "") + j)
                }
            } else {
                array.push(parent ? parent + "." + j : j)
            }
        }
        return array
    }

    convertJsonToCsv(jsonTable, fileName, header = null, headerClean = null) {
        var items = jsonTable
        var replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        try {
            var arr = jsonTable.map(vals => JSON.stringify(vals).split('":"').length)
            var key = arr.indexOf(Math.max.apply(null, arr))
            header = header == null ? this.recupererHeaders(jsonTable[key]) : header

            var csv = [
                headerClean == null ? header.join(';') : headerClean.join(';'), // header row first
                ...items.map(row => header.map((fieldName) => { try { return JSON.stringify(eval("row." + fieldName), replacer) } catch (e) { return null } }).join(';'))
            ].join('\n')
        } catch (error) {
            console.log(error)
            //this.displayAlert("Une erreur est survenue lorsque de la conversion des données", "red")
            //document.getElementById('spinner').classList.add("hidden")
        }
        this.downloadCSV(csv, fileName + ".csv")
    }

    downloadCSV(csv, fileName) {
        // Ajouter le BOM UTF-8
        const BOM = '\uFEFF';
        const csvWithBOM = BOM + csv;

        // Créer un Blob avec le type MIME 'text/csv' et l'encodage UTF-8
        const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' });

        // Vérifier si l'API URL.createObjectURL est disponible
        if (navigator.msSaveBlob) { // Pour IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) { // Pour les navigateurs modernes
                // Créer une URL pour le Blob
                const url = URL.createObjectURL(blob);
                // Attribuer l'URL au href de l'élément <a>
                link.setAttribute('href', url);
                // Définir le nom du fichier pour le téléchargement
                link.setAttribute('download', fileName);
                // Appliquer du style pour masquer le lien (optionnel)
                link.style.visibility = 'hidden';
                // Ajouter le lien au document
                document.body.appendChild(link);
                // Simuler un clic sur le lien pour démarrer le téléchargement
                link.click();
                // Retirer le lien du document
                document.body.removeChild(link);
            }
        }
    }

    cleanSelectFile() {
        document.getElementById('dropzone_file_cleanAdress').value = ""
        document.getElementById('bloc_dropzone_cleanAdress').classList.remove('hidden')
        document.getElementById('block_file_cleanAdress').classList.add('hidden')
    }

    async convertCsvToJson() {
        const reader = new FileReader();
        const env = this
        var XLSX = require("xlsx")

        const file = document.getElementById('dropzone_file_cleanAdress').files[0]

        reader.onload = function (event) {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });

            const sheet_name_list = workbook.SheetNames;
            const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
            env.setState({
                json_data: json_data
            })
        };

        reader.readAsBinaryString(file);
        document.getElementById('name_file_cleanAdress').innerHTML = file.name
        document.getElementById('bloc_dropzone_cleanAdress').classList.add('hidden')
        document.getElementById('block_file_cleanAdress').classList.remove('hidden')
    }

    cutListAdresse() {
        const headers = ['N_Client', 'Rue'];
        const listeJson = this.state.json_data
        console.log(listeJson)
        if (listeJson) {

            // qu'il les bon en tête
            const result = listeJson.every((json) =>
                headers.every((header) => json.hasOwnProperty(header))
            );

            //suite envoie des donnes
            if (result) {
                const listeAdresseDecoupee = listeJson.map((item) => {
                    return {
                        "N_Client": item["N_Client"],
                        adresse: this.splitAddressIgnoreCase(item.Rue),
                    };
                });

                console.log(listeAdresseDecoupee)

                this.convertJsonToCsv(listeAdresseDecoupee, "adresse_Netoyer")

            } else {
                this.displayMessageErrorImport("Fichier non valide, vérifiez que les colonnes codepostal, id_agence, libellé et activité sont présentes.")
            }
        } else {
            this.displayMessageErrorImport("Sélectionner un fichier csv ou excel")

        }

    }

    cutAdresse(adresse) {
        while (adresse.charAt(0) === " ") {
            adresse = adresse.slice(1);
        }
        const typeVoieMap = {
            "grande-rue": "grande rue",
            "LIEUDIT": "lieu-dit",
            // Ajoutez d'autres correspondances ici
        };
        //typeVoieMap.map()
        adresse = adresse.replace("GRANDE RUE", "GRANDE-RUE")
        adresse = adresse.replace("LIEUDIT", "LIEU-DIT")
        adresse = adresse.replace("grande rue", "grande-rue ")
        adresse = adresse.replace("lieu dit", "lieu-dit")
        adresse += " "
        var regex = /^(\d+\/?\d*)\s*(BIS|TER|QUATER|QUINQUIES|SEXIES|SEPTIES|OCTIES)?\s*(?:(.*?)\s+)?(RUE|CHAMP|DOMAINE|CHAMPS|LES|LA|LE|VALLEE|VAL|AVENUE|QUARTIER|BOULEVARD|IMPASSE|ALLEE|ROUTE|CHEMIN|CHEMINS|PLACE|SQUARE|COURS|ANSE|CARREFOUR|CHAUSSÉE|CITÉ|CLOS|CÔTE|COUR|DEGRÉ|DESCENTE|DRÈVE|ESCOUSSIÈRE|ESPLANADE|GAFFE|GRAND\-ROUTE|LIAISON|MAIL|MONTÉE|MONTEE|PLACETTE|PONT|PROMENADE|QUAI|RÉSIDENCE|RANG|RAMPE|ROND\-POINT|RUELLE|SENTE|SENTIER|SQUARE|TRABOULE|TRAVERSE|VENELLE|VILLA|VOIE|BERGE|DIGUE|CUL\-DE\-SAC|ESCALIER|GIRATOIRE|JARDIN|PARVIS|PASSERELLE|ALL|AV|CAR|CHE|CHS|CITE|COR|CRS|DOM|DSC|ECA|ESP|FG|GR|HAM|HLE|IMP|LD|LOT|MAR|MTE|PAS|PL|PLN|PLT|PRO|PRV|QUA|QUAI|RES|ROC|RPT|RTE|SEN|SQ|TPL|TRA|SALLE|REX|GRANDE-RUE|BOIS|FERME|PARC|HAMEAU|LOTISSEMENT|AV.|CH.|IMP.|CH|LIEU\-DIT|LOT.|LIEU DIT|BLD|BD|PASSAGE)\s+(.*)$/i;
        var regex2 = /^(RUE|CHAMP|DOMAINE|CHAMPS|LES|LA|LE|VALLEE|VAL|AVENUE|QUARTIER|BOULEVARD|IMPASSE|ALLEE|ROUTE|CHEMIN|CHEMINS|PLACE|SQUARE|COURS|ANSE|CARREFOUR|CHAUSSÉE|CITÉ|CLOS|CÔTE|COUR|DEGRÉ|DESCENTE|DRÈVE|ESCOUSSIÈRE|ESPLANADE|GAFFE|GRAND\-ROUTE|LIAISON|MAIL|MONTÉE|MONTEE|PLACETTE|PONT|PROMENADE|QUAI|RÉSIDENCE|RANG|RAMPE|ROND\-POINT|RUELLE|SENTE|SENTIER|SQUARE|TRABOULE|TRAVERSE|VENELLE|VILLA|VOIE|BERGE|DIGUE|CUL\-DE\-SAC|ESCALIER|GIRATOIRE|JARDIN|PARVIS|PASSERELLE|ALL|AV|CAR|CHE|CHS|CITE|COR|CRS|DOM|DSC|ECA|ESP|FG|GR|HAM|HLE|IMP|LD|LOT|MAR|MTE|PAS|PL|PLN|PLT|PRO|PRV|QUA|QUAI|RES|ROC|RPT|RTE|SEN|SQ|TPL|TRA|SALLE|REX|GRANDE-RUE|BOIS|FERME|PARC|HAMEAU|LOTISSEMENT|AV.|CH.|IMP.|CH|LIEU\-DIT|LOT.|LIEU DIT|BLD|BD|PASSAGE)\s+(.*)$/i;

        var match = regex.exec(adresse);
        var index = 0


        if (match) {
            console.log(match)
            index = 3
            var num = match[1] + (match[2] ? " " + match[2].toLowerCase() : "")
        } else {
            match = regex2.exec(adresse);
            if (match) {
                var num = null
            } else {
                return { num: null, type_voie: null, voie: adresse };
            }
        }

        if (match[1 + index].toLowerCase() == "la" || match[1 + index].toLowerCase() == "le" || match[1 + index].toLowerCase() == "les") {
            var type_voie = null
            var voie = match[1 + index] + " " + match[2 + index].trim()
        } else {
            var type_voie = match[1 + index].toLowerCase() == "grande-rue" ? "grande rue" : match[1 + index].toLowerCase()
            var voie = match[2 + index].trim()
        }

        return {
            num: num,
            type_voie: type_voie,
            voie: voie
        };
    }

    replaceInsensitive(original, target, replacement) {
        const regex = new RegExp(target, 'gi');
        return original.replace(regex, replacement);
    }

    splitAddressIgnoreCase(address) {
        const streetTypes = [
            "RUE", 'RUES', "CHAMP", "DOMAINE", "CHAMPS", "LES", "LA", "LE", "VALLEE", "VAL", "AVENUE", "QUARTIER",
            "BOULEVARD", "IMPASSE", "ALLEE", "ALLÉE", "ROUTE", "CHEMIN", "CHEMINS", "PLACE", "SQUARE", "COURS", "ANSE",
            "CARREFOUR", "CHAUSSÉE", "CITÉ", "CLOS", "CÔTE", "COUR", "DEGRÉ", "DESCENTE", "DRÈVE", "ESCOUSSIÈRE",
            "ESPLANADE", "GAFFE", "GRAND-ROUTE", "LIAISON", "MAIL", "MONTÉE", "MONTEE", "PLACETTE", "PONT",
            "PROMENADE", "QUAI", "RÉSIDENCE", "RANG", "RAMPE", "ROND-POINT", "RUELLE", "SENTE", "SENTIER",
            "SQUARE", "TRABOULE", "TRAVERSE", "VENELLE", "VILLA", "VOIE", "BERGE", "DIGUE", "CUL-DE-SAC",
            "ESCALIER", "GIRATOIRE", "JARDIN", "PARVIS", "PASSERELLE", "ALL", "AV", "CAR", "CHE", "CHS",
            "CITE", "COR", "CRS", "DOM", "DSC", "ECA", "ESP", "FG", "GR", "HAM", "HLE", "IMP", "LD",
            "LOT", "MAR", "MTE", "PAS", "PL", "PLN", "PLT", "PRO", "PRV", "QUA", "QUAI", "RES", "ROC",
            "RPT", "RTE", "SEN", "SQ", "TPL", "TRA", "SALLE", "REX", "GRANDE-RUE", 'VIEILLE-ROUTE', "BOIS", "FERME",
            "PARC", "HAMEAU", "LOTISSEMENT", "AV.", "CH.", "IMP.", "CH", "LIEU-DIT", "LOT.", "LIEU DIT",
            "BLD", "BD", "PASSAGE", 'rempart', 'chaussé', 'rocade', 'rt', 'ancienne-route'
        ];

        address = this.replaceInsensitive(address, "LIEUDIT", "LIEU-DIT")
        address = this.replaceInsensitive(address, "grande rue", "grande-rue")
        address = this.replaceInsensitive(address, "vieille route", "vieille-route")
        address = this.replaceInsensitive(address, "ancienne route", "ancienne-route")
        address = this.replaceInsensitive(address, " av ", " AVENUE ")

        // Créer une expression régulière pour les types de rues, insensible à la casse
        const regex = new RegExp(`\\b(${streetTypes.join('|')})\\b`, 'i');
        const match = address.match(regex);

        if (match) {
            const index = match.index;
            const type = match[0];
            const before = address.slice(0, index).trim();
            const after = address.slice(index + type.length).trim();
            console.log(before, type, after)
            return {
                num: before,
                type_voie: type,
                voie: after
            };
        } else {
            return {
                num: '',
                type_voie: '',
                voie: address
            };
        }

    }


    dropHandler(ev) {
        ev.preventDefault();
        document.getElementById('bloc_dropzone_cleanAdress').files = ev.dataTransfer.files;
        this.convertCsvToJson()
    }


    displayMessageErrorImport(message) {
        const env = this
        env.setState({
            messageErrorCleanAdress: message
        })
        setTimeout(function () {
            env.setState({
                messageErrorCleanAdress: null
            })
        }, 10000);
    }

    render() {
        return (
            <>
                <div class="w-full flex flex-col max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                    <div class="text-center p-5 flex-auto justify-center">
                        <svg class="w-16 h-16 flex items-center text-green-600 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                            <FontAwesomeIcon icon={faFileExcel} />
                        </svg>
                        <h2 class="text-xl font-bold py-4 ">Découper les adresses des client</h2>
                        <p class="text-sm text-gray-500 px-8">Separe le nom de rue, qualiter de rue, et la rue</p>
                    </div>

                    {this.state.importSucces ?
                        <div className='flex flex-col '>
                            <NotifBar message="Les secteur ont été mis à jour" color="green" />
                        </div>
                        : <div className='flex flex-col space-y-5'>


                            {this.state.messageErrorCleanAdress != null ? <span id='messageErrorCleanAdress' class='mx-auto text-red-500'>{this.state.messageErrorCleanAdress} </span> : null}
                            <div className='flex flex-col'>
                                <div className='mx-auto h-6 w-6 mb-1'>
                                    <span class="text-[8px] absolute text-blue-500 hover:text-blue-600 cursor-pointer group ">
                                        <div class='hidden group-hover:block absolute text-xs text-black bg-gray-200 rounded-md py-1 px-2 cursor-default bottom-[90%] left-4 w-96 text-justify '>
                                            Vérifiez que les colonnes N_Client, Rue sont présentes.
                                        </div>
                                        <div className='flex items-center justify-center h-6 w-6 rounded-full border-2 border-blue-500 hover:border-blue-500 text-base '>
                                            <FontAwesomeIcon icon={faInfo} />
                                        </div>
                                    </span>
                                </div>
                            </div>


                            <label for="dropzone_file_cleanAdress" id='bloc_dropzone_cleanAdress' onDrop={this.dropHandler} onDragOver={(e) => e.preventDefault()} class="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed bg-gray-50 hover:border-gray-300 hover:bg-gray-100 bg-white p-6 text-center">
                                <p class="mt-2 text-gray-500 tracking-wide">Cliquer pour parcourir ou glisser votre document excel. </p>
                                <input id="dropzone_file_cleanAdress" type="file" class="hidden" onChange={this.convertCsvToJson} accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                            </label>

                            <div id='block_file_cleanAdress' class=" rounded-md bg-[#F5F7FB] py-4 px-8 hidden">
                                <div class="flex items-center justify-between">
                                    <span id='name_file_cleanAdress' class="truncate pr-3 text-base font-medium text-[#07074D]">
                                        banner-design.png
                                    </span>
                                    <button class="text-[#07074D] " onClick={this.cleanSelectFile}>
                                        <svg className='w-6 h-6'>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="p-3  mt-2 text-center space-x-4 md:block">
                                <button onClick={this.cutListAdresse} class="mb-2 md:mb-0 bg-red-500  bg-gray-700 hover:bg-gray-800 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600">
                                    Découper
                                </button>
                            </div>
                        </div>}
                </div>


            </>
        );
    }
}

export default (props) => (
    <CleanAdressByCustomer
        {...props}
        navigation={useNavigate()}
    />
);